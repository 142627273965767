import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import "./prog-track.css";
import "./ProductAdd.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faCalendarAlt,
  faLessThan,
  faPhone,
  faShield,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

function ProductAdd({ OffcanvasCloseButton }) {
  const [current, setCurrent] = useState(1);

  const [product_id, setProduct_Id] = useState(0);

  const handleNext = () => {
    if (current < 3) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const getIconForStep = (step) => {
    switch (step) {
      case 1:
        return faUser;

      case 2:
        return faEnvelope;

      case 3:
        return faShield;
    }
  };

  return (
    <>
      <section className="StepForm-recruiter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12   p-0  mb-2">
              {/* <div className="card stepForm_card px-0  pb-0 mt-3 mb-3">
                <div className="card-body p-0"> */}
              <div className="row">
                <div className="col-sm-12">
                  <form id="msform">
                    <div className="wizard-form  p-0">
                      {current === 1 && (
                        <Step1
                          handlePrevious={handlePrevious}
                          handleNext={handleNext}
                          product_id={product_id}
                          setProduct_Id={setProduct_Id}
                          OffcanvasCloseButton={OffcanvasCloseButton}
                        />
                      )}
                      {current === 2 && (
                        <Step2
                          handleNext={handleNext}
                          handlePrevious={handlePrevious}
                          product_id={product_id}
                          setProduct_Id={setProduct_Id}
                        />
                      )}
                      {current === 3 && (
                        <Step3
                          handleNext={handleNext}
                          handlePrevious={handlePrevious}
                          product_id={product_id}
                          setProduct_Id={setProduct_Id}
                          OffcanvasCloseButton={OffcanvasCloseButton}
                        />
                      )}
                    </div>
                  </form>
                </div>
              </div>
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductAdd;
